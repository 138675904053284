var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.feedBackRequest)?_c('div',[(_vm.showSharableURL)?_c('b-message',{attrs:{"title":"Sua URL compartilhável","closable":false}},[_c('b',[_vm._v("IMPORTANTE:")]),_vm._v(" Use esse link com cautela. Qualquer pessoa que o obtiver poderá ver o seu pedido de feedback e fornecer um feedback para você. "),_c('br'),_c('br'),_c('b',[_vm._v("COPIE A SUA URL DE FEEDBACK:")]),_c('span',[_vm._v(" "+_vm._s(_vm.finalAteOFuturoShareURL)+" ")]),_c('br')]):_vm._e(),_c('div'),_c('h2',{staticClass:"subtitle is-4 has-text-centered"},[_vm._v(" "+_vm._s(_vm.LABEL_TipoDeFeedback)+" "),_c('b-icon',{attrs:{"icon":_vm.ICON_TipoDeFeedback}})],1),_c('div',{staticClass:"columns box",staticStyle:{"background-color":"#efefdd"}},[_c('div',{staticClass:"column is-2"},[_c('vue-svg-gauge',{class:{
          'animate__animated animate__heartBeat animate__faster':
            _vm.feedBackRequest.QualidadePedido >= 100,
        },attrs:{"start-angle":-110,"end-angle":110,"value":_vm.feedBackRequest.QualidadePedido,"separator-step":0,"min":0,"max":100,"gauge-color":"#00D380","scale-interval":1}})],1),_vm._m(0)]),_c('b-field',{attrs:{"label":_vm.LABEL_TipoDeFeedbackEsperado}},[_c('section',[_c('b-message',{attrs:{"type":"is-success"}},[_vm._v(" "+_vm._s(_vm.feedBackRequest.ResumoHabilidade)+" ")])],1)]),_c('b-field',{attrs:{"label":_vm.LABEL_Detalhes}},[_c('section',[_c('b-message',{attrs:{"type":"is-success"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.ComputedDetalhes)}})])],1)]),(_vm.feedBackRequest.Areas)?_c('b-field',{attrs:{"label":"Áreas de Conhecimento"}},[_c('section',[_c('b-taglist',_vm._l((_vm.feedBackRequest.Areas),function(tag){return _c('b-tag',{key:tag,attrs:{"type":"is-warning"}},[_vm._v(_vm._s(tag))])}),1)],1)]):_vm._e(),(_vm.feedBackRequest.QuestoesFeedback)?_c('b-field',{attrs:{"label":"Métricas de Avaliação (⭐)"}},[_c('section',[_c('b-taglist',_vm._l((_vm.feedBackRequest.QuestoesFeedback),function(tag){return _c('b-tag',{key:tag.texto,attrs:{"type":"is-success"}},[_vm._v(_vm._s(tag.texto))])}),1)],1)]):_vm._e(),(
      _vm.feedBackRequest.WantsExternalReference &&
      _vm.feedBackRequest.ExternalReferenceType != 'Link' &&
      _vm.feedBackRequest.VideoStorageURL != null
    )?_c('b-field',{attrs:{"label":"Vídeo de demonstração da habilidade"}},[_c('video',{staticClass:"preview",attrs:{"src":_vm.feedBackRequest.VideoStorageURL,"controls":""}})]):_vm._e(),(
      _vm.feedBackRequest.WantsExternalReference &&
      _vm.feedBackRequest.ExternalReferenceType == 'Link'
    )?_c('b-field',{attrs:{"label":"Link de Referência"}},[_c('b-field',{attrs:{"expanded":""}},[_c('a',{attrs:{"href":_vm.feedBackRequest.ExternalReferenceURL,"target":"blank"}},[_vm._v(_vm._s(_vm.feedBackRequest.ExternalReferenceURL))])]),_c('div',{staticClass:"columns"},[(_vm.videoId != null)?_c('youtube',{attrs:{"expanded":"","video-id":_vm.videoId}}):_vm._e()],1),_c('br')],1):_vm._e(),_c('b-field',{attrs:{"label":"Finalidade do aprimoramento:"}},[_c('b-message',[_vm._v(_vm._s(_vm.FinalidadeHabilidade.text))])],1),_c('h2',{staticClass:"subtitle is-4 has-text-centered"},[_vm._v("👊🏻 Medidas já tomadas ⚡")]),(_vm.feedBackRequest.AcoesTomadas != null)?_c('b-field',{attrs:{"label":"Olha o que já foi feito"}},[_c('section',[_c('b-message',{attrs:{"type":"is-success"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.ComputedAcoesTomadas)}})])],1)]):_c('div',[_vm._v(" "+_vm._s(_vm.LABEL_NenhumaAcaoTomada)+" ")]),(_vm.feedBackRequest.JaRefletiu)?_c('div',[_c('h2',{staticClass:"subtitle is-4 has-text-centered"},[_vm._v("🧠 Reflexões feitas 💭")]),_vm._l((_vm.feedBackRequest.Reflexoes),function(reflexao){return _c('b-message',{key:reflexao.pergunta,attrs:{"title":reflexao.pergunta,"closable":false}},[_c('div',{domProps:{"innerHTML":_vm._s(reflexao.resposta.replaceAll('\n', '<br />'))}})])})],2):_vm._e()],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-10"},[_c('h3',{staticClass:"title is-4"},[_vm._v("💪 FORÇA DO PEDIDO")]),_vm._v(" A força do pedido indica o nível de detalhamento fornecido para que seu feedback seja: "),_c('b',[_vm._v("descritivo, específico, dirigido, oportuno e esclarecedor.")])])}]

export { render, staticRenderFns }