var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('empty-layout',[_c('div',{staticClass:"box"},[_c('h1',{staticClass:"title is-2"},[_vm._v(" Hey, "+_vm._s(_vm.$store.state.currentUser.displayName)+", what would you rather learn? ")]),(_vm.player1 && _vm.player2)?_c('div',{staticClass:"columns box",staticStyle:{"background-color":"#c6ecae"}},[_c('div',{staticClass:"column is-5",class:{
          'has-background-primary animate__animated animate__tada ':
            _vm.winnerPlayer == _vm.player1,
          'animate__animated animate__fadeOutTopLeft': _vm.changedPlayer1,
        },on:{"click":function($event){_vm.winnerPlayer = _vm.player1}}},[_c('h2',{staticClass:"subtitle is-size-1 has-text-centered",class:{ 'white-text': _vm.winnerPlayer == _vm.player1 }},[_vm._v(" "+_vm._s(_vm.player1.name)+" ")]),_c('b-image',{attrs:{"src":("https://source.unsplash.com/featured/?&" + (_vm.player1.name)),"alt":_vm.player1.name,"ratio":"601by235"}})],1),_c('div',{staticClass:"column is-2"},[_c('h2',{staticClass:"subtitle is-size-1 has-text-centered"},[_vm._v("OR")])]),_c('div',{staticClass:"column is-5",class:{
          'has-background-primary animate__animated animate__tada ':
            _vm.winnerPlayer == _vm.player2,
          'white-text': _vm.winnerPlayer == _vm.player2,
          'animate__animated animate__fadeOutTopLeft': _vm.changedPlayer2,
        },on:{"click":function($event){_vm.winnerPlayer = _vm.player2}}},[_c('h2',{staticClass:"subtitle is-size-1 has-text-centered",class:{ 'white-text': _vm.winnerPlayer == _vm.player2 }},[_vm._v(" "+_vm._s(_vm.player2.name)+" ")]),_c('b-image',{attrs:{"src":("https://source.unsplash.com/featured/?&" + (_vm.player2.name)),"alt":_vm.player2.name,"ratio":"601by235"}})],1)]):_vm._e(),(_vm.player1 && _vm.player2)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12 has-text-centered"},[_c('b-button',{staticClass:"is-fullwidth",attrs:{"type":"is-success","size":"is-large"},on:{"click":function($event){return _vm.play()}}},[_vm._v("⚡ Select!")])],1)]):_vm._e(),_c('h1',{staticClass:"title is-2"},[_vm._v(" Don't find a good contender skill? Suggest one! ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('b-field',{attrs:{"label":"Skill"}},[_c('b-input',{model:{value:(_vm.newSkillSuggested),callback:function ($$v) {_vm.newSkillSuggested=$$v},expression:"newSkillSuggested"}})],1),_c('b-button',{attrs:{"type":"is-success","size":"is-large"},on:{"click":function($event){return _vm.insertSkill()}}},[_vm._v("⚡ Post!")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }