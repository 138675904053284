import { render, staticRenderFns } from "./ArvoreConhecimento.vue?vue&type=template&id=7fe1139a&"
import script from "./ArvoreConhecimento.vue?vue&type=script&lang=js&"
export * from "./ArvoreConhecimento.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports