<template>
    <layout-default>
        teste
       <OKR/>
    </layout-default>
</template>

<script> 
import OKR from '../../components/OKR.vue'
export default {
    components:{OKR}
}
        
</script>