
const TestAreas = [];

if (process.env.NODE_ENV !== "production") {
    TestAreas.push("_TEST1");
    TestAreas.push("_TEST2");
    TestAreas.push("_TEST3");
}


export const Areas =
    [...TestAreas,
        "Idiomas",
        "Comunicação",
        "Criatividade",
        "Colaboração",
        "Adaptabilidade",
        "Flexibilidade",
        "Franchising - Franquias e Franqueamento",
        "Raciocínio Analítico",
        "Vendas",
        "Processamento de Linguagem Natural",
        "Estratégias Corporativas",
        "Agilidade",
        "Kanban",
        "Scrum",
        "Parenting",
        "Matemática",
        "Liderança",

        "Tradução",
        "Inglês",
        "Espanhol",
        "Sueco",
        "Produção de Vídeos",
        "Produção de Áudio",
        "Desenvolvimento de Software",
        "Computação em Nuvem",
        "Inteligência Artificial",
        "Aplicações Móveis",
        "Persuasão",
        "Discurso",
        "Prática para Entrevista",
        "Comunicação Corporativa",
        "Marketing Digital",
        "Marketing em Mídias Sociais",
        "Mídias Sociais",
        "Jornalismo",
        "Desenho",
        "Desenho Digital",
        "Pintura",
        "Pintura Aquarela",
        "Pintura Óleo",
        "Pintura com Lápis de Cor",
        "Pintura Digital",
        "Tratamento de Imagens",
        "Photoshop (e ferramentas de imagens)",
        "Arquitetura de TI",
        "Arquitetura de Corporativa",
        "Inteligência Interpessoal",
        "Planejamento de Carreiras",
        "Pitch de Negócios",
        "Novos Negócios",
        "Dicção",
        "Gamification",
        "Inovação",
        "Apresentações",
        "Falar em público",
        "Storytelling",
        "RPA - Robotic Process Automation",
        "Inovação aberta",
        "Trabalho em equipe",
        "Design Thinking",
        "Design Sprint",
        "Marketing pessoal ",
        "Negociação",

        "Processos ITIL",
        "Gestão de Projetos",
        "Gestão de Times",
        "Gestão de Pessoas",


        "Clareza de Demonstração de Ideias",
        "Educação",
        "Meta-aprendizado",

        "Desenvolvimento Humano",
        "RH",
        "Coaching",
        "Recrutamento",
        "Entrevista",
        "Carreira",
        "Gestão - Gerenciamento de Pessoas",
        "Aprendizagem",
        "OKRs",
        "Experimentação",
        "Lean Startup",
        "Treinamento",
        "Marketing",
        "Desenvolvimento de Jogos",
        "Teste de Software",
        "Javascript",
        "Python",
        "Node.js",
        "Polymer",
        "React",
        "RxJS",
        "Vue.js",
        "No Code - Low Code",
        "SDLC - Systems Development Life Cycle",
        "Fotografia",
        "Exposição (Fotografia)",
        "Regra dos terços (Fotografia)",
        "Iluminação (Fotografia)",
        "Engenharia de Software"
        , "Viagens"
        , "Varejo",
        "Envelhecimento Humano",
        "Gerontologia",
        "Poesia",
        "Xadrez",
        "Oratória",
        "Relações interpessoais",
        "Relações sociais",
        "Relacionamentos",
        "Engajamento de Pessoas ou Equipes",
        "TI - Tecnologia da Informação",
        "Digitalização",
        "Segurança da Informação",
        "Diversidade Cultural",
        "TOC - Teoria das Restrições",
        "Reuniões eficientes",
        "Mapeamento de Processos"
        ,
        "Gerenciamento de Processos de Negócio - Business Process Management - BPM"
    ]

export default Areas



// const possibleTags = [
//   {
//     type: "Geral",
//     items: [
//       "Idiomas",
//       "Comunicação",
//       "Liderança",
//       "Criatividade",
//       "Colaboração",
//       "Adaptabilidade",
//       "Flexibilidade",
//       "Raciocínio Analítico",
//       "Gerenciamento de Pessoas",
//       "Vendas",
//       "Processamento de Linguagem Natural",
//       "Estratégias Corporativas",
//     ],
//   },
//   {
//     type: "Empreendedorismos & Administração",
//     items: [
//       "Idiomas",
//       "Comunicação",
//       "Liderança",
//       "Criatividade",
//       "Colaboração",
//       "Gerenciamento de Pessoas",
//       "Vendas",
//       "Estratégias Corporativas",
//     ],
//   },
//   { type: "Idiomas", items: ["Tradução", "Inglês", "Espanhol", "Sueco"] },
//   {
//     type: "Áudio e Vídeo",
//     items: ["Produção de Vídeos", "Produção de Áudio"],
//   },
//   {
//     type: "Tecnologia",
//     items: [
//       "Desenvolvimento de Software",
//       "Computação em Nuvem",
//       "Inteligência Artificial",
//       "Aplicações Móveis",
//       "Persuasão",
//       "Discurso",
//       "Prática para Entrevista",
//       "Comunicação Corporativa",
//       "Marketing Digital",
//       "Marketing em Mídias Sociais",
//       "Mídias Sociais",
//       "Marketing",
//       "Jornalismo",
//     ],
//   },
//   {
//     type: "Comunicação",
//     items: [
//       "Persuasão",
//       "Discurso",
//       "Prática para Entrevista",
//       "Comunicação Corporativa",
//       "Marketing Digital",
//       "Marketing em Mídias Sociais",
//       "Mídias Sociais",
//       "Marketing",
//       "Jornalismo",
//     ],
//   },
//   {
//     type: "Desenvolvimento de Software",
//     items: [
//       "Desenvolvimento de Jogos",
//       "Teste de Software",
//       "Javascript",
//       "Python",
//       "Node.js",
//       "Polymer",
//       "React",
//       "RxJS",
//       "Vue.js",
//       "No Code / Low Code",
//     ],
//   },
// ];